<template>
  <div>
    <template v-if="popover">
      <b-button size="min" variant="gray" id="flow-popover" href="#" tabindex="0">
        <b-icon-question-circle class="mr-0"></b-icon-question-circle>
        <span hidden-xs class="ml-1">How it works</span></b-button>

      <b-popover target="flow-popover"
                 triggers="focus blur"
                 placement="bottom">
        <div class="popover-title px-3 py-3">
          Actions Flow
        </div>
        <LiquidityFlowSteps
            :currentStep=currentStep
            :time1=time1
            :time2=time2
            :baseToken="$store.state.tokens.baseToken"
            popover
            flowView="col"
        ></LiquidityFlowSteps>
      </b-popover>
    </template>

    <template v-else>
      <LiquidityFlowSteps
          :currentStep=currentStep
          :time1=time1
          :time2=time2
          :baseToken="$store.state.tokens.baseToken"
          flowView="row"
      ></LiquidityFlowSteps>
    </template>
  </div>
</template>

<script>

export default {
  name: "LiquidityFlow",
  components: {
    LiquidityFlowSteps: () => import("@/components/governance/LiquidityFlowSteps")
  },
  props: {
    currentStep: Number,
    time1: Number,
    time2: Number,
    popover: Boolean,
  },
}
</script>

<style scoped lang="scss">

  .popover-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: $gray-800;
  }


</style>
